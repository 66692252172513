import { styles } from '@sdir/sds';
import {useEffect, useState} from 'react';
import styled from 'styled-components';
import {
    getVesselsNisNor,
    getVesselsBygg,
    getReservedVessels
} from 'services/APIService';
import { EmptyState } from 'ui/EmptyState';
import { Filter } from 'ui/Filter';
import { VesselResultItem } from 'ui/VesselResultItem';
import { SearchBar } from 'ui/SearchBar';
import { TitleBanner } from 'ui/TitleBanner';
import {SimpleVesselDTO} from "models/Models";
import {useSearchParams} from "react-router-dom";
import {getTranslations} from "../../helpers/Helpers";
import {ShipyardResultItem} from "../../ui/ShipyardResultItem";
import {ReservedVesselResultItem} from "../../ui/ReservedVesselResultItem";

export type Register = "nisnor" | "bygg" | "reserved";

export interface RegisterOption {
    value: Register,
    name: string
}

function isOfTypeRegister (keyInput: string): keyInput is Register {
    return ['nisnor', 'bygg', 'reserved'].includes(keyInput);
}

const StartPageComponent = () => {
    const startPageLang = getTranslations().startPage;

    const options : RegisterOption[] = [
        {
            value: "nisnor",
            name: startPageLang.nisnor
        },
        {
            value: "bygg",
            name: startPageLang.bygg
        },
        {
            value: "reserved",
            name: startPageLang.reserved
        }
    ]
    
    const [searchParams, setSearchParams] = useSearchParams();
    const [searchQuery, setSearchQuery] = useState("");
    const [registerOption, setRegisterOption] = useState<RegisterOption>(options[0]);
    const [searchResult, setSearchResult] = useState<any>();
    const [filteredSearchResult, setFilteredSearchResult] = useState<any>();
    const [isLoading, setIsLoading] = useState(false);
    
    useEffect(() => {
        const searchParam = searchParams.get('search');
        if (searchParam)
            setSearchQuery(searchParam.trim());

        const registerParam = searchParams.get('register');
        if (registerParam) {
            const registerOption = options.find(option => option.value === registerParam);
            if (registerOption)
                setRegisterOption(registerOption);
        }

        if (searchParam && registerParam && isOfTypeRegister(registerParam))
            updateSearch(searchParam.trim(), registerParam);

    }, [])

    useEffect(() => {
        if (searchQuery && searchResult) {
            setSearchParams({search: searchQuery, register: registerOption.value});
            updateSearch(searchQuery);
        }
    }, [registerOption])

    const updateSearch = async function (searchQuery : string, register? : Register) {
        setIsLoading(true);
        const registerValue = register ?? registerOption.value;

        switch (registerValue) {
            case "nisnor":
                await getVesselsNisNor(searchQuery)
                    .then((results) => {
                        setSearchResult(results);
                        setFilteredSearchResult(results);
                        setIsLoading(false);
                    });
                break;
            case "bygg":
                await getVesselsBygg(searchQuery)
                    .then((results) => {
                        setSearchResult(results);
                        setFilteredSearchResult(results);
                        setIsLoading(false);
                    });
                break;
            case "reserved":
                await getReservedVessels(searchQuery)
                    .then((results) => {
                        setSearchResult(results);
                        setFilteredSearchResult(results);
                        setIsLoading(false);
                    });
                break;
        }
    }

    const updateFilteredResult = (filteredResult : SimpleVesselDTO[]) => {
        setFilteredSearchResult(filteredResult);
    }

    function handleOptionSelect(option : any) {
        setRegisterOption(option);
    }

    const onSubmit = async function () {
        setSearchParams({search: searchQuery, register: registerOption.value})
        await updateSearch(searchQuery);
    }

    return (
        <Main>
            <div className="l-container">
                <TitleBanner title={startPageLang.searchRegisters}></TitleBanner>
                <SearchBar
                    searchLabel={startPageLang.searchLabel}
                    searchPlaceholder={ registerOption.value === "nisnor" ? startPageLang.searchPlaceholderNisnor : registerOption.value === "bygg" ?  startPageLang.searchPlaceholderBuild : startPageLang.searchPlaceholderReserved }
                    dropdownLabel={startPageLang.dropdownLabel}
                    dropdownOptions={ options }
                    submitText={startPageLang.submitText}
                    onSubmit={ onSubmit }
                    setSearchQuery={ setSearchQuery }
                    searchQuery={ searchQuery }
                    handleOptionSelect={handleOptionSelect}
                    registerOption={registerOption}
                />
                {!searchResult && (
                    isLoading ? (
                        <Spinner>
                            <SpinnerInner />
                        </Spinner>
                    ) : (
                        <EmptyState
                            title={startPageLang.findAVessel}
                            message={startPageLang.findAVesselDescription}
                        />)
                )}
                {searchResult && (
                    searchResult?.length > 0 ? (
                        <ResultWrapper>
                            <ResultCount role={"status"}>{filteredSearchResult?.length} {startPageLang.resultsFound}
                                <strong>{registerOption.value === "nisnor" ? startPageLang.theNisnorRegister : registerOption.value === "bygg" ? startPageLang.theByggRegister : startPageLang.theReservedVessels}</strong></ResultCount>
                            <Filter toggleText={startPageLang.limitSearch} searchQuery={searchQuery}
                                     searchResult={searchResult} updateFilteredResult={updateFilteredResult}
                                     register={registerOption.value}></Filter>
                            <ResultContainer>
                                {isLoading ? (
                                    <Spinner>
                                        <SpinnerInner />
                                    </Spinner>
                                ) : (
                                    <ResultList>
                                        {
                                            filteredSearchResult?.sort((a : any, b : any) => {
                                                const constructionCompare = (a.constructionNumber ?? 0) - (b.constructionNumber ?? 0);
                                                if (constructionCompare !== 0) return constructionCompare;
                                                else return a.name?.localeCompare(b.name)
                                            }).map((vessel : any) => {
                                                
                                                switch (registerOption.value) {
                                                    case "nisnor":
                                                        return (<VesselResultItem key={vessel.vesselId?.toString() + vessel.callSign + vessel.constructionNumber + vessel.vesselName + vessel.register} vessel={vessel} searchString={searchQuery}
                                                                                  register={registerOption.value}/>);
                                                    case "bygg":
                                                        return (<ShipyardResultItem key={vessel.vesselId?.toString() + vessel.callSign + vessel.constructionNumber + vessel.vesselName + vessel.register} vessel={vessel} searchString={searchQuery}
                                                                                    register={registerOption.value}/>);
                                                    case "reserved":
                                                        return (<ReservedVesselResultItem key={vessel.vesselId?.toString() + vessel.callSign + vessel.constructionNumber + vessel.vesselName + vessel.register} vessel={vessel}/>);
                                                }
                                            })}
                                    </ResultList>
                                )}
                            </ResultContainer>
                        </ResultWrapper>
                    ) : (
                        isLoading ? (
                            <Spinner>
                                <SpinnerInner />
                            </Spinner>
                        ) : (
                            <EmptyState
                                title={startPageLang.noResults}
                                message={startPageLang.noResultsDescription}
                            />
                        )
                    )
                )}
            </div>
        </Main>
    )
}

const Main = styled.main`
    padding-top: 6rem;
    padding-bottom: 12rem;
    color: ${styles.colors.font.text};
    `

const ResultWrapper = styled.div`
    display: grid;
    gap: 2rem 4rem;
    margin-top: 6rem;
    padding-top: 3rem;
    border-top: 1px solid ${styles.colors.border};
    @media (min-width: 1000px) {
        grid-template-columns: min(30%, 350px) auto;
    }
`

const ResultContainer = styled.div`
    container-name: grid-column;
    container-type: inline-size;
`

const ResultCount = styled.p`
    margin: 0;
    color: ${styles.colors.font.text85};
    grid-column: 1 / -1;
    font-size: 1.75rem;
`

const ResultList = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 2rem;
`

export const Spinner = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    height: 30rem;
    width: 100%;

    @media (min-width: 1000px) {
        height: 50rem;
    }
`

export const SpinnerInner = styled.div`
    animation: rotation 1s linear infinite;
    box-sizing: border-box;
    border: 0.75rem solid #FFF;
    border-bottom-color: ${styles.colors.primary};
    border-radius: 50%;
    display: inline-block;
    height: 8rem;
    width: 8rem;
    
    @keyframes rotation {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`

export const StartPage = StartPageComponent;