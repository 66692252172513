import {faGlobeAmericas, faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import { Icon, SdirFooter, SdirHeader, SdirModal } from "@sdir/sds";
import { useState } from "react";
import {Outlet, useNavigate} from "react-router-dom";
import {getLanguageFromURL, getTranslations, otherLanguageURL} from "../helpers/Helpers";
import styled from "styled-components";

const language = getLanguageFromURL();

const modalContent = language === "no" 
    ? (<> <h2>Hvordan fungerer søket?</h2>
        <p>Du kan søke på fartøy som er, eller har vært, registrert i Norsk Ordinært Skipsregister (NOR), Norsk
            Internasjonalt Skipsregister (NIS) og Skipsbyggingsregisteret (BYGG).</p>
        <p>Flere av feltene i databasen er søkbare, men kun fartøyets IMO-nummer og norske kjenningssignal er unike
            søkekriterier. Filtrer gjerne søket ved hjelp av funksjonen «avgrens søket»</p>
        <p>Ta gjerne kontakt dersom du ikke får treff, så sjekker vi for å være helt sikker.</p>
        <p><a title="Søketips skipssøk" href="https://prod.sdir.no/skipsregistrene/soketips-til-skipssok/" target="_blank" rel="noreferrer">Her finner du
            søketips!&nbsp;</a></p>
        <h2>Trenger du en offisiell attest eller sertifikat?</h2>
        <p>Send en bestilling til <a href="mailto:nis-nor@sdir.no">nis-nor@sdir.no</a>. Attestene faktureres etter&nbsp; <a
            href="https://www.sdir.no/skipsregistrene/registreringsgebyrer-nor/">gjeldene
            satser for NOR</a>, eller <a
            href="https://www.sdir.no/skipsregistrene/registreringsgebyrer-nis/">NIS.</a>&nbsp;
        </p>
    </>)
:
    (<> <h2>Hvow does the search work?</h2>
        <p>You can look up vessels that are, or that have been registered in the Norwegian Ordinary Ship Register (NOR), the Norwegiain International Ship Register (NIS) or the Shipbuilding Register (BYGG).</p>
        <p>Several of the fields in the database are searchable, but only the vessel's IMO number and Norwegian call sign are unique search criteria. You can filter the search result using the function "limit search".</p>
        <p>Do not hesitate to contact us should you not find what you are searching for. We will double check the register for you to be sure!</p>
        <p><a title="Search tips vessel search" href="https://prod.sdir.no/en/the-norwegian-ship-registers/search-tips-for-ship-search/" target="_blank" rel="noreferrer">Here are some useful search tips! &nbsp;</a></p>
        <h2>Do you need an official reference or certificate?</h2>
        <p>Order by sending an e-mail to <a href="nis-nor@sdir.no">nis-nor@sdir.no</a>. The references will be invoiced pursuant to&nbsp; <a
                href="https://www.sdir.no/skipsregistrene/registreringsgebyrer-nor/">applicable rates for NOR,</a>, or <a
                href="https://www.sdir.no/skipsregistrene/registreringsgebyrer-nis/">NIS.</a>&nbsp;
        </p>
    </>);


const AppLayoutComponent = () => {
    const layoutLang = getTranslations().layout;
    
    const navigate = useNavigate();
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [helpButton, setHelpButton] = useState<Element>();

    return (
        <AppLayoutWrapper>
            <HeaderWrapper>
                <SdirHeader
                    appName={layoutLang.title}
                    backLink={{ url: layoutLang.URLtoSDIR, text: layoutLang.goToSDIR}}
                    maxContentWidth="1200px"
                    toolMenuItems={[
                        {
                            type: 'button',
                            text: layoutLang.language,
                            icon: <Icon name={faGlobeAmericas as any} padding="0" />,
                            onClick: () => {
                                navigate(otherLanguageURL(), {replace: true});
                                navigate(0);
                            }
                        },
                        {
                            type: 'button',
                            text: layoutLang.help,
                            icon: <Icon name={faInfoCircle as any} padding="0" />,
                            onClick: () => {
                                if (document.activeElement)
                                    setHelpButton(document.activeElement);
                                
                                setModalIsOpen(true);
                            }
                        }
                    ]}
                    menuToggleText="Meny"
                />
            </HeaderWrapper>
            <SdirModal visible={modalIsOpen} onCancel={() => {
                setModalIsOpen(false)
                if (helpButton)
                    (helpButton as HTMLElement).focus();
            }}>
                {modalContent}
            </SdirModal>
            <Wrapper>
                <Outlet />
            </Wrapper>
            <FooterWrapper>
                <SdirFooter
                    contactHeader={layoutLang.contact}
                    contactLinks={[
                        { url: 'https://www.sdir.no/om-direktoratet/kontakt-oss/kontakt/', text: layoutLang.contact, type: 'external' },
                        { url: 'https://www.sdir.no/om-direktoratet/presentasjon-av-direktoratet/', text: layoutLang.about, type: 'external' }
                    ]}
                    socialHeader={layoutLang.follow}
                    socialLinks={[
                        { url: 'https://www.facebook.com/sjofartsdir/', text: 'Facebook', type: 'facebook' },
                        { url: 'https://www.linkedin.com/company/sjofartsdir', text: 'Linkedin', type: 'linkedin' }
                    ]}
                    tagline={layoutLang.tagline}
                    maxContentWidth="1200px"
                />
            </FooterWrapper>
        </AppLayoutWrapper>
    );
};

const AppLayoutWrapper = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
`;

const HeaderWrapper = styled.div`
    @media print {
      display: none;
    }
`
const FooterWrapper = styled.div`
    @media print {
      display: none;
    }
`

const Wrapper = styled.div`
    flex-grow: 1;
    min-height: 90vh;
`;

export const AppLayout = AppLayoutComponent;